/**
 * This file contains helper functions that may be used on either the server or
 * the client.
 *
 * Note - this file is used by the /utilities sub-package, which handles running
 * the importer etc. All imports in this file must be available in that project
 * also, so don't include packages that are only installed in www.
 */

import { Product } from "../model/product.js";
import { ProductVariant } from "../../../data/packages/model/src/product.js";
import { ProductCategoryConfig } from "../model/product-category-config.js";
import { ModelResponseRecommendation } from "@lib/model/recommender-model.js";
import { Attribute, AttributeConfigurationMap } from "@lib/model/attribute.js";

const IMAGE_PROCESSOR_URL = "https://dv9dhd03d71d4.cloudfront.net";

/**
 * Sort a list of types that have weight, by weight.
 *
 * @param items - A list of objects of a type that has a "weight" property.
 * @returns - The sorted list.
 */
export function weightSort<T extends { weight: number }>(items: T[]): T[] {
  return items.sort((itemA, itemB) => itemA.weight - itemB.weight);
}

export enum AssetType {
  Icon = "images/icons",
  Photo = "images/photos",
  ProductImage = "images/products",
  Image = "images",
}

/**
 * Gets the file extension based on a filename string.
 *
 * @param filename
 */
export const getExtension = (filename: string) =>
  filename?.substring(filename.lastIndexOf(".") + 1);

/**
 * Gets an image URL using our image processing stack.
 *
 * @param filename - The name of just the file
 * @param assetType - The type of asset.
 * @param resize - Resizing options, e.g. width/height
 * @param productCategory - The name of the product category.
 */
export const getImageUrl = (
  filename: string,
  assetType: AssetType,
  resize?: {
    width?: number;
    height?: number;
  },
  productCategory?: string
) => {
  const ext = getExtension(filename);
  const key = `${assetType}/${
    productCategory?.length > 0 ? productCategory + "/" + filename : filename
  }`;

  // Don't send SVG requests to the image processor.
  if (ext === "svg") {
    return `https://${process.env.NEXT_PUBLIC_ASSETS_DOMAIN}/${key}`;
  }

  const payload = {
    bucket: process.env.NEXT_PUBLIC_ASSETS_BUCKET,
    key: decodeURIComponent(key),
    edits: resize
      ? {
          resize: {
            ...resize,
            fit: "cover",
          },
        }
      : undefined,
  };

  return `${IMAGE_PROCESSOR_URL}/${Buffer.from(
    JSON.stringify(payload)
  ).toString("base64")}`;
};

/**
 * Converts cents to dollars and formats.
 *
 * @param cents - A number of cents.
 * @returns - A formatted dollars string.
 */
export const centsToDollars = (cents: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const dollars = cents / 100;
  if (Number.isNaN(dollars)) {
    return "";
  }

  return formatter.format(dollars);
};

/**
 * Converts formatted dollars to cents.
 *
 * @param dollars - e.g. "$499.99" or "$500.00".
 * @returns - Integer number of cents, e.g. 49999 or 50000.
 */
export const convertDollarsToCents = (dollars: string): number => {
  // Strip dollar sign and convert to a number.
  const dollarsFloat = parseFloat(dollars.replace(/[$,]/g, ""));

  // Round before returning, just in case the spreadsheet has extra decimals
  // somehow. Typically, output from this function gets stored in an integer
  // column.
  return Math.round(dollarsFloat * 100);
};

export interface ProductLabels {
  /**
   * The best default, one-line label for a product, custom-crafted per category.
   */
  shortLabel: string;

  /**
   * Label containing all the information needed to narrow down the product.
   */
  fullLabel: string;

  /**
   * If the label is meant to be divided into two lines, this is the more prominent
   * one. Usually paired with secondaryLabel. The main use case is product card
   * titles.
   */
  primaryLabel: string;

  /**
   * The secondary, less prominent half of a product label if it is displayed in a
   * divided way. Usually paired with primaryLabel. The main use case is product
   * card subtitles.
   */
  secondaryLabel: string;

  /**
   * A (usually) longer, one-line label, optimized for search.
   */
  seoLabel: string;

  /**
   * The meta-description for product landing pages.
   */
  metaDescription: string;
}

/**
 * Gets a structure containing different labels for a product.
 *
 * @param product
 * @returns
 */
export const getProductLabels = (
  product: Product,
  getAttributeFunction = (name, product) => product.attributes[name].value
) => {
  const categoryName = product.metadata.categoryName;
  const getAttribute = (name: string) => getAttributeFunction(name, product);

  let primary: string,
    secondary: string,
    seo: string,
    meta: string,
    full: string,
    short: string;

  switch (categoryName) {
    case "tvs": {
      const tvScreenSize = getAttribute("screenSize");
      const panelFamily = getAttribute("panelFamily");
      const os = getAttribute("operatingSystem");
      const is4K = getAttribute("screenResolution") === "4K";

      primary = `${product.manufacturer} ${tvScreenSize}" ${panelFamily}`;
      secondary = product.shortName;
      full = `${product.manufacturer} ${tvScreenSize}” ${panelFamily} ${
        is4K ? "4K" : ""
      } ${os} (${product.model})`;
      short = `${product.manufacturer} ${product.shortName} (${panelFamily})`;
      seo = `${product.manufacturer} ${product.shortName} (${tvScreenSize}" ${panelFamily})`;
      meta = `Comprehensive price, features and technical specs for the ${seo}. See if it is the right TV for you.`;

      break;
    }
    case "laptops": {
      const laptopScreenSize = getAttribute("screenSize");
      primary = product.model;
      secondary = `${product.manufacturer} ${laptopScreenSize}”`;
      seo = short = `${product.manufacturer} ${primary}`;
      full = `${product.manufacturer} ${product.model}`;
      meta = `Comprehensive price, features and technical specs for the ${seo}. See if it is the right laptop for you.`;

      break;
    }
    case "smartphones":
      primary = product.model;
      secondary = product.manufacturer;
      full = short = seo = `${secondary} ${primary}`;
      meta = `Comprehensive price, features and technical specs for the ${seo}. See if it is the right phone for you.`;

      break;
    case "headphones":
      primary = product.model;
      secondary = product.manufacturer;
      full = short = seo = `${secondary} ${primary}`;
      meta = `Comprehensive price, features and technical specs for the ${seo}. See if these are the right headphones for you.`;

      break;
    case "monitors":
      const panelFamily = getAttribute("panelFamily");
      const panelSubType = getAttribute("screenSubtype");

      const combined = `${panelSubType}${
        !["OLED", "LED"].includes(panelFamily) ? ` ${panelFamily}` : ``
      }`;
      primary = product.model;
      secondary = product.manufacturer;
      full = short = seo = `${secondary} ${primary} (${combined})`;
      meta = `Comprehensive price, features and technical specs for the ${seo}. See if it is the right monitor for you.`;
      break;
    default:
      primary = product.model;
      secondary = product.manufacturer;
      full = short = seo = `${secondary} ${primary}`;
      meta = `Comprehensive price, features and technical specs for the ${seo}. See if it is the right product for you.`;
  }

  if (product.seoName !== undefined) seo = `${product.seoName}`;

  return {
    primaryLabel: primary,
    secondaryLabel: secondary,
    seoLabel: seo,
    metaDescription: meta,
    shortLabel: short,
    fullLabel: full,
  };
};

export const getProductImage = (
  product: Product,
  dimensions: { width?: number; height?: number }
) =>
  getImageUrl(
    product.image,
    AssetType.ProductImage,
    dimensions,
    product.metadata.categoryName
  );

/**
 * Gets the url of the hero image for a category.
 */
export const getCategoryImage = (category: ProductCategoryConfig) => {
  return getImageUrl(
    category.heroImage.src,
    AssetType.Image,
    category.heroImage.dimensions
  );
};

export const canonicalize = (url: string) => {
  return `https://www.perfectrec.com${url}`;
};

/**
 * Gets the URL for a category recommender.
 */
export const getRecommenderUrl = (
  productCategory: ProductCategoryConfig,
  skipSplash = false
) =>
  `/${productCategory.vertical}/${
    productCategory.recommenderSlug
  }/recommendation${skipSplash ? "?skipSplash=1" : ""}`;

/**
 * Gets the URL for the landing page of the recommender (e.g. /electronics/best-phones).
 */
export const getRecommenderLandingUrl = (
  productCategory: ProductCategoryConfig
) => `/${productCategory.vertical}/${productCategory.recommenderSlug}`;

/**
 * Gets the absolute path of a product landing page (domain not included). Does not include slash
 */
export const getProductLandingPagePath = (
  product: Product,
  productCategory: ProductCategoryConfig
) => {
  return `${productCategory.vertical}/${productCategory.slug}/${product.slug}`;
};

export const buildProductLandingPageUrl = (
  product: Product,
  productCategorySlug: string,
  productCategoryVertical: string
) => {
  return `/${productCategoryVertical}/${productCategorySlug}/${product.slug}`;
};

/**
 * Gets the absolute url of a product landing page (domain not included). Includes slash
 */
export const getProductLandingPageUrl = (
  product: Product,
  productCategory: ProductCategoryConfig
) => {
  return `/${getProductLandingPagePath(product, productCategory)}`;
};

/**
 * Gets the absolute path of a product landing page by slug (domain not included). Does not include slash
 */
export const getProductLandingPagePathBySlug = (
  productSlug: string,
  productCategory: ProductCategoryConfig
) => {
  return `${productCategory.vertical}/${productCategory.slug}/${productSlug}`;
};

/**
 * Gets the absolute url of a product landing page by slug (domain not included). Includes slash
 */
export const getProductLandingPageUrlBySlug = (
  productSlug: string,
  productCategory: ProductCategoryConfig
) => {
  return `/${getProductLandingPagePathBySlug(productSlug, productCategory)}`;
};

/**
 * Gets the absolute URL of a scenario page (domain not included).
 */
export const getScenarioUrl = (
  productCategory: ProductCategoryConfig,
  slug: string
) => `/${productCategory.vertical}/${productCategory.slug}/${slug}`;

export const getScenarioIndexUrl = (productCategory: ProductCategoryConfig) =>
  `/${productCategory.vertical}/scenarios/${productCategory.slug}`;

/**
 * Gets the absolute URL of a "vs" page, which compares two products (domain not included).
 */
export const getVsPageUrl = (
  products: Product[],
  productCategory: ProductCategoryConfig,
  omitLeadingSlash = false
) => {
  const sortedProductSlugs = products.map((p) => p.slug).sort();
  const path = `${productCategory.vertical}/${productCategory.slug}/${sortedProductSlugs[0]}--vs--${sortedProductSlugs[1]}`;

  return omitLeadingSlash ? path : `/${path}`;
};

export const getVsPageUrlBySlugs = (
  productSlugs: string[],
  productCategory: ProductCategoryConfig,
  omitLeadingSlash = false
) => {
  const sortedProductSlugs = productSlugs.sort();
  const path = `${productCategory.vertical}/${productCategory.slug}/${sortedProductSlugs[0]}--vs--${sortedProductSlugs[1]}`;

  return omitLeadingSlash ? path : `/${path}`;
};

/**
 * Gets the url to a category comparison index page.
 */
export const getCompareIndexUrl = (
  productCategory: ProductCategoryConfig,
  page?: number,
  filterParams?: { [key: string]: string }
) => getPagedCategoryIndexUrl(productCategory, "compare", page, filterParams);

/**
 * Gets the URL to a category product listing page.
 */
export const getCategoryProductsUrl = (
  productCategory: ProductCategoryConfig,
  page?: number,
  filterParams?: { [key: string]: string | string[] }
) => {
  return getPagedCategoryIndexUrl(
    productCategory,
    "products",
    page,
    filterParams
  );
};

export const getCategoryScenariosUrl = (
  productCategory: ProductCategoryConfig,
  page?: number
) => {
  return getPagedCategoryIndexUrl(productCategory, "scenarios", page);
};

/**
 * Generic URL template for category index/listing page.
 */
const getPagedCategoryIndexUrl = (
  productCategory: ProductCategoryConfig,
  slug: string,
  page?: number,
  filterParams?: { [key: string]: string | string[] }
) => {
  const urlBase = `/${productCategory.vertical}/${slug}/${productCategory.slug}`;
  const params = new URLSearchParams({});
  if (filterParams?.path) {
    delete filterParams.path;
  }

  if (page > 1) {
    params.set("page", page.toString());
  }

  if (filterParams) {
    Object.entries(filterParams).forEach(([param, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((singleValue) => params.append(param, singleValue));
        } else {
          params.set(param, value);
        }
      }
    });
  }

  return Array.from(params).length > 0
    ? `${urlBase}?${params.toString()}`
    : urlBase;
};

/**
 * The feature toggle repository.
 *
 * See product-category-config.json for category specific features toggles.
 */
const featureToggleState = {
  scenarioListing: false,
};

/**
 * Determines whether a feature is enabled.
 *
 * @param name
 * @returns
 */
export const isFeatureEnabled = (name) => {
  return featureToggleState[name] || false;
};

export const groupByKey = <t>(
  array: t[],
  key: string
): { [key: string]: t[] } => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
};

export const validPrice = (purchaseInfo: string) => {
  return !isNaN(Number(purchaseInfo.replace(",", "")));
};

/**
 * Converts attribute array to a map.
 */
export const addAttributeMap = (apiProduct: Product): Product => {
  const product = { ...apiProduct } as any;
  product.attributes = {};
  apiProduct.attributes.forEach((attribute) => {
    product.attributes[attribute.slug] = attribute;
  });
  return product;
};

/**
 * Builds a recommendation
 *
 * @TODO We can probably retire the old Recommendation structure with nested
 * product, in favor of using schemes that rely on ModelResponseRecommendation and
 * Product types.
 */
export const buildRecommendation = (
  modelRec: ModelResponseRecommendation,
  product: Product
) => ({
  product,
  confidence: modelRec.confidence,
  explanations: modelRec.explanations,
});

/**
 * Gets a product attribute as a float.
 */
export const getProductAttributeAsFloat = (
  product: Product,
  attributeName: string
) => {
  if (!product) return NaN;

  const float = parseFloat(product.attributes[attributeName]?.value);
  return roundDecimal(float);
};

/*
 * Gets feature toggle state for a feature/category.
 *
 * @param categoryName
 * @param feature
 * @returns
 */
export const getCategoryFeatureState = (
  productCategoryConfig: ProductCategoryConfig,
  feature: string
) => {
  return productCategoryConfig?.features
    ? productCategoryConfig.features[feature]
    : false;
};

/**
 * Determines whether part of the category is under construction.
 */
export const getUnderConstruction = (
  category: ProductCategoryConfig,
  feature: "recommender" | "prices" | "landingPages"
) => {
  return category?.underConstruction?.[feature];
};

/**
 * Determine whether the app should show confindence scores for a particular
 * context (e.g. done page or before done page.)
 */
export const categoryMayShowConfidence = (
  productCategoryConfig: ProductCategoryConfig,
  context: string
) => {
  return productCategoryConfig?.showConfidenceScores?.[context];
};

/**
 *
 * @param categoryConfig
 * @param localSnapshotId
 * @param table
 * @returns
 */
export const deriveCategoryVersion = (
  categoryConfig: ProductCategoryConfig,
  table: string,
  localSnapshotId = "latest"
) => {
  return categoryConfig.dataVersions[table];
};

/**
 * Derive a human readable "<number> <interval>" value for "time ago" purposes.
 */
export const timeSince = (timestamp: number) => {
  const seconds = Math.floor((new Date().getTime() - timestamp) / 1000);

  const getInterval = (interval: number, singular: string) => {
    const plurals = {
      year: "years",
      month: "months",
      day: "days",
      hour: "hours",
      minute: "minutes",
      second: "seconds",
    };

    return Math.floor(interval) === 1 ? singular : plurals[singular];
  };

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " " + getInterval(interval, "year");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " " + getInterval(interval, "month");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " " + getInterval(interval, "day");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " " + getInterval(interval, "hour");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " " + getInterval(interval, "minute");
  }
  return Math.floor(seconds) + " " + getInterval(interval, "second");
};

export const getRandomSample = (array: any[], sampleSize: number) => {
  const sample = array.slice();
  let currentIndex = array.length;
  let temporaryValue, randomIndex;

  while (currentIndex > 0 && currentIndex - sample.length < sampleSize) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    temporaryValue = sample[currentIndex];
    sample[currentIndex] = sample[randomIndex];
    sample[randomIndex] = temporaryValue;
  }

  return sample.slice(currentIndex, currentIndex + sampleSize);
};

export const getRandomProducts = (
  products: Product[],
  n: number,
  mustInclude?: Product[]
) => {
  const randomSet = getRandomSample(products, n);

  if (!mustInclude) {
    return randomSet;
  }

  for (const includeProduct of mustInclude) {
    if (!randomSet.find((product) => product.id === includeProduct.id)) {
      randomSet.push(includeProduct);
    }
  }

  return randomSet;
};

/**
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 */
export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

/**
 * Make sure a function only gets called at certain intervals.
 */
export const debounce = (func, delay = 1000) => {
  let timerId;

  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null;
    }, delay);
  };
};

/**
 * Gets a date in one of our nice formats.
 */
export const displayDate = (dateString: string) => {
  const date = new Date(dateString);
  const options = { timeZone: "UTC" };
  const month = date.toLocaleString("en-US", { month: "long", ...options });
  const year = date.toLocaleString("en-US", { year: "numeric", ...options });
  const day = date.toLocaleString("en-US", { day: "numeric", ...options });

  return {
    // July 2023
    toMonth: () => {
      return `${month} ${year}`;
    },
    // July 3, 2023
    toDay: () => {
      return `${month} ${day}, ${year}`;
    },
    toYear: () => {
      return year;
    },
  };
};

/**
 * Takes an array of strings and turns it into a grammatically correct list that
 * is usuable in a sentence, i.e. "foo, bar and baz"
 */
export const listToCommaString = (list: string[]) => {
  if (list.length == 1) {
    return list[0];
  }
  const first = list.slice(0, -1).join(", ");
  const last = list[list.length - 1];
  return `${first} and ${last}`;
};

/**
 * Determines whether the current environment is production.
 *
 * This works in both browser and in node.
 */
export const isProduction = (window = null) => {
  return getEnvironmentName(window) === "prod";
};

/**
 * Gets a name for the current environment.
 *
 * This works in both browser and in node.
 */
export const getEnvironmentName = (window = null) => {
  // If it is on the server, check the env variables.
  const isBrowser = window !== null;

  if (process?.env?.APP_ENV)
    return process?.env?.APP_ENV as "local" | "preview" | "prod";

  // Allow overriding environment for testing.
  const override = isBrowser
    ? window?.sessionStorage.getItem("overrideEnv")
    : null;

  if (override === "preview" || override === "prod") {
    return override as "local" | "preview" | "prod";
  }

  // Otherwise it is client side. Check URL.
  if (isBrowser && window?.location?.hostname === "www.perfectrec.com") {
    return "prod";
  }

  if (
    isBrowser &&
    window?.location?.hostname?.includes("preview.perfectrec.com")
  ) {
    return "preview";
  }

  return "local";
};

/**
 * Gets a string that represents a product row's status value in the spreadsheet.
 */
const getStatusComparator = (window = null) => {
  return isProduction(window) ? "live" : "staged";
};

/**
 * Gets the set of products suitable for display in user-facing lists, i.e. products that:
 * - Have status enabled for the current environment
 * - Are the main product for their price group, if that is applicable.
 *
 * We need the list of catalog products for:
 * - The compare tool dropdown
 * - Browse products page
 * - Product autocomplete pickers
 *
 * Note - any place where we get product IDs from the model and need to show a
 * product card, we don't limit our pool of products to catalog products. Thus,
 * the recommender may show products that are not in the catalog.
 */
export const getCatalogProducts = (
  products: Product[],
  category: ProductCategoryConfig,
  window = null
) => {
  if (!category.hasPriceGroups) {
    // Filter by status if necessary.
    return products.filter(
      // For backwards compat, don't filter out products with no status object.
      (product) => productInEnvCatalog(product, window)
    );
  } else {
    const groupedProducts = getPriceGroupedProducts(products, window);
    return Object.keys(groupedProducts).map(
      (group) => groupedProducts[group][0]
    );
  }
};

/**
 * Determine whether a product is enabled in the catalog for the current
 * environment.
 *
 * This works in both browser and in node.
 */
export const productInEnvCatalog = (
  product: Product | ProductVariant,
  window = null
) => {
  return (
    product.status["catalogString"] &&
    (product.status["catalogString"] === "live" ||
      product.status["catalogString"] === getStatusComparator(window))
  );
};

/**
 * Arranges a list of products into a map keyed by price group.
 */
const getPriceGroupedProducts = (products: Product[], window = null) => {
  const canonicalProducts: { [group: string]: Product[] } = {};
  for (const product of products) {
    const group = product.priceGroup;
    if (!group || !productInEnvCatalog(product, window)) {
      continue;
    }

    canonicalProducts[group] = [
      ...(canonicalProducts[group] ? canonicalProducts[group] : []),
      product,
    ];
  }

  return canonicalProducts;
};

/**
 *
 * @param product
 * @param status
 * @param window
 * @returns
 */
export const productIsDisabled = (
  product: Product | ProductVariant,
  status: "recommender" | "catalog"
) => {
  return (
    product.status &&
    product.status[`${status}String`] &&
    product.status[`${status}String`] === "disabled"
  );
};

/**
 * Sleep for a bit.
 */
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Round to a certain number of decimal places.
 */
export const roundDecimal = (input: number | string, place = 1) => {
  const number = typeof input === "number" ? input : parseFloat(input);
  if (Number.isInteger(number)) {
    return number;
  }
  return Math.round(number * 10 ** place) / 10 ** place;
};

/** Redirect mapping for deprecated -> new categories */
const REDIRECT_CATEGORY_MAP = {
  earbuds: "headphones",
} as const;

/**
 * The paths that are identified in our app that would contain
 * deprecated categories. We are trying to limit edge cases with a simple
 * find and replace on the URL path.
 */
const getCategoryUrlPaths = (
  deprecatedCategory: keyof typeof REDIRECT_CATEGORY_MAP
) => {
  return [`/${deprecatedCategory}/`, `/compare/${deprecatedCategory}`] as const;
};

type ShouldRedirectDeprecatedUrlResult =
  | { shouldRedirect: false }
  | { shouldRedirect: true; newUrl: string };

export const shouldRedirectDeprecatedUrl = (
  url: string
): ShouldRedirectDeprecatedUrlResult => {
  // Bail if the url doesn't contain a deprecated category.
  const deprecatedCategory = Object.keys(REDIRECT_CATEGORY_MAP).find((path) =>
    url.includes(path)
  ) as keyof typeof REDIRECT_CATEGORY_MAP;
  if (!deprecatedCategory) return { shouldRedirect: false };

  // Handle best-[category] urls. We want to avoid redirecting scenarios URLs
  // that might have the "best-[old-world]" in the slug somewhere. e.g.
  // /electronics/headphones/best-earbuds-for-phone-calls.
  const categoryToRedirect = REDIRECT_CATEGORY_MAP[deprecatedCategory];
  const urlParts = url.split("/");
  if (urlParts[4] === `best-${deprecatedCategory}`) {
    return {
      shouldRedirect: true,
      newUrl: url.replace(
        `best-${deprecatedCategory}`,
        `best-${categoryToRedirect}`
      ),
    };
  }

  // Handle the specific deprecated path patterns.
  const deprecatedUrlPaths = getCategoryUrlPaths(deprecatedCategory);
  const matchingDeprecatedPath = deprecatedUrlPaths.find((path) =>
    url.includes(path)
  );
  if (!matchingDeprecatedPath) return { shouldRedirect: false };

  const newCategoryRedirectPath = matchingDeprecatedPath.replace(
    deprecatedCategory,
    categoryToRedirect
  );

  const newUrl = url.replace(matchingDeprecatedPath, newCategoryRedirectPath);
  return {
    shouldRedirect: true,
    newUrl,
  };
};

/**
 * Handler for the "vs" page URL in cases for old links. Provides a safe redirect handler function
 * to use after the determination has been made.
 *
 * @param {{ url, redirectFunc }} - A URL to check if it is a VS page and needs sorting and a redirect function
 * that takes a URL and redirects to it.
 * @returns
 */
export const vsPageRedirectHandler = <ResponseType>({
  url,
  redirectFunc,
}: {
  url: URL;
  redirectFunc: (url: URL) => ResponseType;
}) => {
  const { result, sortedProductUrl } = areVsPageUrlProductsSorted(url);
  const shouldRedirect = !result;

  const redirectHandler = () => {
    if (!shouldRedirect)
      throw new Error(
        "This function should only be called when a redirect is needed."
      );

    return redirectFunc(sortedProductUrl);
  };

  return { redirectHandler, shouldRedirect, sortedProductUrl };
};

/**
 * Handling for the "vs" page URL in cases for old links
 * where the products are not sorted alphabetically.
 *
 * @returns { { result: boolean, sortedProductUrl: URL } }
 * True result means the URL is either not a VS page or the products are sorted alphabetically.
 * False result means the URL is a VS page and the products are not sorted alphabetically.
 *
 * sortedProductUrl is the URL with the products sorted alphabetically or the original URL if it is not a VS page.
 */
export const areVsPageUrlProductsSorted = (url: URL) => {
  const sortedProductUrl = getSortedVsProductUrl(url);
  // compare the URL paths excluding any query strings
  const isUrlSorted = url.pathname === sortedProductUrl.pathname;

  return {
    result: isUrlSorted,
    sortedProductUrl,
  };
};

/**
 * Gets the sorted product VS page URL if applicable
 */
export const getSortedVsProductUrl = (url: URL): URL => {
  if (!isVsPageUrl(url)) return url;

  // Get last part of URL path excluding the query string

  const pathParts = url.pathname.split("/");
  const vsProductsPath = pathParts.pop();

  const productSlugs = vsProductsPath.split("--vs--");
  const sortedProductSlugs = productSlugs.sort();

  const sortedVsProductPath = sortedProductSlugs.join("--vs--");
  const basePath = pathParts.join("/");

  const newUrlPathname = `${basePath}/${sortedVsProductPath}`;

  // add query string if it exists
  const newSortedUrl = new URL(`${newUrlPathname}${url.search}`, url.origin);
  return newSortedUrl;
};

/**
 * If the url is a vs page url
 *
 * @example `https://www.perfectrec.com/electronics/phones/apple-iphone-14-plus--vs--apple-iphone-13`
 *
 */
export const isVsPageUrl = (url: URL | string): boolean => {
  const urlObj = typeof url === "string" ? new URL(url) : url;

  const lastUrlPath = urlObj.pathname.split("/").pop();
  return lastUrlPath.includes("--vs--");
};

/**
 * Add a number of years to a month-year date.
 */
export const addYearsToMonthYearNumeric = (
  month: number,
  year: number,
  yearsToAdd: number
) => {
  // Create a Date object using the provided numeric month and year.
  const parsedDate = new Date(year, month - 1, 1);

  // Add the specified number of years.
  parsedDate.setFullYear(parsedDate.getFullYear() + yearsToAdd);

  // Format the output as "(Month Year)".
  const formattedOutput = `${parsedDate.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  })}`;

  return formattedOutput;
};

/**
 * Tokenizes an input string and determines whether all input tokens are found in
 * the target string.
 */
export const matchAllWordsFuzzy = (
  inputString: string,
  targetString: string
) => {
  // Tokenize the input string by splitting it into words.
  const tokens = inputString.split(/\s+/).filter((string) => string);

  // Initialize an array to store matching tokens.
  return tokens.every((token) => {
    return targetString.toLowerCase().includes(token.toLowerCase());
  });
};

/**
 * A set of category-specific resolvers for determining what may display as a
 * similar-product for a given product.
 */
const categorySimilarProductsRules = {
  tvs: (product, candidate) =>
    product.attributes?.["screenSize"]?.value ===
    candidate.attributes?.["screenSize"]?.value,
} as {
  [categoryName: string]: (product: Product, candidate: Product) => boolean;
};

/**
 * Gets a set of products that are similar in price to a particular product.
 */
export const getSimilarProducts = (
  product: Product,
  productsPool: Product[],
  category: ProductCategoryConfig,
  n = 6,
  excludeProducts: Product[] = []
) => {
  const price = product?.bestPrice;
  const productId = product.id;

  const sortedProducts = productsPool
    .filter(
      (p) =>
        // No empty price.
        p.bestPrice != null &&
        // Not the same as the comparee.
        p.id !== productId &&
        // Not variants of the comparee.
        getProductLabels(p).shortLabel !==
          getProductLabels(product).shortLabel &&
        // Not one of the products we are supposed to exclude.
        !excludeProducts
          .map((excludeProduct) => excludeProduct.id)
          .includes(p.id) &&
        // Passes category specific rules, if there are any.
        (!categorySimilarProductsRules[category.name] ||
          categorySimilarProductsRules[category.name](product, p))
    )
    .sort(
      (a, b) => Math.abs(a.bestPrice - price) - Math.abs(b.bestPrice - price)
    );

  const uniqueShortLabels = new Set();
  const closestProducts = [];

  for (const product of sortedProducts) {
    if (closestProducts.length === n) {
      break;
    }
    if (uniqueShortLabels.has(getProductLabels(product).shortLabel)) {
      continue;
    }
    uniqueShortLabels.add(getProductLabels(product).shortLabel);
    closestProducts.push(product);
  }

  return closestProducts;
};

/**
 * Sort callback: sort products by short label.
 */
export const productLabelCompare = (productA: Product, productB: Product) => {
  const labelA = getProductLabels(productA).shortLabel;
  const labelB = getProductLabels(productB).shortLabel;

  return labelA.localeCompare(labelB);
};

/**
 * Sort callback: sort products by release date.
 */
export const productReleaseDateCompare = (
  productA: Product,
  productB: Product
) => {
  const dateStringA = productA.attributes["releaseDate"]?.value;
  const dateStringB = productB.attributes["releaseDate"]?.value;

  const dateA = new Date(dateStringA);
  const dateB = new Date(dateStringB);

  const timestampA = dateA.getTime();
  const timestampB = dateB.getTime();

  return timestampA - timestampB;
};

/*
 * Config for pages that are noncanonical but still need to be included in the sitemap due to popularity
 */
export const nonCanonicalVsPagesForSitemap = {
  tvs: [
    ["hisense-75u6h", "hisense-75u8h"],
    ["hisense-65u8h", "lg-oled65c2"],
    ["sony-xr-55a80k", "sony-xr-65a80j"],
    ["insignia-55f301na22", "vizio-v555-j01"],
    ["hisense-50u6h", "hisense-65u6g"],
    ["sony-xr-65a80k", "sony-xr-65a90j"],
    ["hisense-75u7h", "sony-xr-65x90k"],
    ["hisense-65u7h", "hisense-75u8h"],
    ["lg-oled65c2", "sony-xr-55x90k"],
    ["samsung-qn55s95b", "sony-xr-65a80k"],
    ["hisense-55u6g", "hisense-65u6h"],
    ["hisense-65u7g", "hisense-65u7h"],
    ["lg-oled55b2", "lg-oled83c1"],
    ["tcl-43s435", "tcl-43s455"],
    ["sony-xr-55a80k", "sony-xr-55x90k"],
    ["sony-xr-65x90j", "sony-xr-65x90k"],
    ["lg-oled55b2", "lg-oled65c1"],
    ["insignia-24f202na23", "vizio-d24fm-k01"],
    ["sony-kd-55x85k", "sony-xr-85x90k"],
    ["lg-oled65b2", "sony-xr-65a80k"],
    ["lg-oled55c1", "samsung-qn65s95b"],
    ["hisense-50u6g", "hisense-75u6h"],
    ["amazon-4k43m600a", "insignia-43f301na22"],
    ["sony-48a90k", "sony-xr-55a90j"],
    ["samsung-qn50q80b", "sony-xr-55x90k"],
    ["sony-xr-55a80j", "sony-xr-55x90k"],
    ["samsung-qn60q60b", "samsung-qn65qn90b"],
    ["samsung-qn55qn90b", "samsung-qn65qn90c"],
    ["hisense-65u8h", "lg-oled55b2"],
    ["hisense-55u6h", "tcl-55s546"],
    ["sony-kd-50x85k", "sony-kd-65x85j"],
    ["samsung-qn55q60b", "sony-kd-55x80k"],
    ["sony-42a90k", "sony-xr-65a95k"],
    ["samsung-qn75qn90b", "sony-xr-85x95k"],
    ["samsung-qn55qn90b", "sony-xr-65x90k"],
    ["samsung-qn55qn85b", "sony-xr-85x90k"],
    ["samsung-qn55q80b", "samsung-qn65q60b"],
    ["lg-oled48c2", "sony-xr-65x95k"],
  ],
  smartphones: [],
  headphones: [],
  laptops: [],
  tablets: [],
  monitors: [],
};

/**
 * These are the popular vs pages for the vs index. Make sure the first compared product comes first in the alphabet, or it will
  be a 404.
 */
export const popularVsPagesForVsIndex = {
  smartphones: [
    ["apple-iphone-15", "apple-iphone-14"],
    ["apple-iphone-15-pro", "apple-iphone-15"],
    ["apple-iphone-15", "samsung-galaxy-s23"],
    ["apple-iphone-15-pro-max", "google-pixel-8-pro"],
    ["samsung-galaxy-s22-ultra", "samsung-galaxy-s23-ultra"],
    ["google-pixel-8", "apple-iphone-15"],
    ["google-pixel-7", "google-pixel-8"],
    ["apple-iphone-15-pro-max", "samsung-galaxy-s23-ultra"],
    ["google-pixel-6a", "google-pixel-7a"],
  ],
  laptops: [
    [
      "apple-macbook-air-15-16-gb-512-gb",
      "apple-macbook-pro-16-m2-pro-16-gb-512-gb",
    ],
    ["acer-nitro-5-15-2023-i5-rtx-4050", "asus-tuf-gaming-f15-rtx-4070"],
    ["dell-inspiron-14-5420", "hp-envy-x360-15-ryzen-7-2023"],
    ["dell-inspiron-16-5625", "hp-envy-x360-15-ryzen-7-2023"],
    ["gigabyte-aero-16", "razer-blade-14-rtx-4070-2023"],
    ["lenovo-legion-5-pro-rtx-4070", "lenovo-legion-5i-pro-rtx-4060-32gb-1tb"],
    ["apple-macbook-pro-16-m2-max-30c-32-gb-1-tb", "dell-alienware-x17-r2"],
    ["dell-inspiron-14-5420", "dell-latitude-5430-16gb"],
    ["apple-macbook-pro-14-m2-pro-16-gb-512-gb", "dell-xps-15"],
  ],
  tvs: [
    ["hisense-65u6h", "hisense-65u8h"],
    ["insignia-65f301na23", "vizio-v655m"],
    ["samsung-qn65s90c", "sony-xr-65a95l"],
    ["hisense-65u8h", "lg-oled65c2"],
    ["samsung-qn32ls03b", "samsung-qn65qn90c"],
    ["hisense-65u7h", "sony-xr-65x90k"],
    ["hisense-65u6h", "hisense-65u6g"],
    ["sony-xr-65a80k", "sony-xr-65a90j"],
    ["lg-oled65c2", "sony-xr-65x90k"],
  ],
  headphones: [
    ["apple-airpods-3rd-generation", "apple-airpods-pro-2"],
    ["bose-700", "sony-wf1000xm5"],
    ["sennheiser-momentum-4", "sony-wh-1000xm5"],
    ["bose-700", "sennheiser-momentum-4"],
    [
      "sennheiser-momentum-true-wireless-2",
      "sennheiser-momentum-true-wireless-3",
    ],
    ["skullcandy-dime", "skullcandy-dime-2"],
    ["jbl-live-pro-2", "soundcore-life-p3"],
    ["jabra-elite-active-75t", "jbl-live-pro-2"],
    ["7hz-timeless", "moondrop-blessing-2"],
  ],
  monitors: [
    ["dell-alienware-aw2723df", "asus-pg279qm"],
    ["asus-pg27aqdm", "lg-27gr95qe"],
    ["dell-alienware-aw3423dw", "dell-alienware-aw3423dwf"],
    ["asus-pg32ucdm", "dell-alienware-aw3225qf"],
  ],
};

export const addCommas = (number: number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * Gets the lowercase version of a category singular or plural noun, unless it
 * should never be lowercase (e.g. TVs).
 */
export const lowerCaseCategoryWord = (
  category: ProductCategoryConfig,
  field: "noun" | "pluralNoun" | "label"
) => {
  const noLowerCaseCategories = ["tvs"];
  if (noLowerCaseCategories.includes(category.name)) {
    return category[field];
  }

  return category[field].toLowerCase();
};

/**
 * Gets the phrase to use for the "variant" concept for a given category.
 */
export const getCategoryVariantsPhrase = (category: ProductCategoryConfig) => {
  return category.variantsPhrase || "Configuration";
};

/**
 * This function finds the appropriate canonical slugs for two given products based on special set of rules
 */
export const getCanonicalCompareSlugs = (products: Product[]) => {
  return getCanonicalCompare(products).map((product) => product.slug);
};

/**
 * This function finds the appropriate canonical ids for two given products based on special set of rules
 */
export const getCanonicalCompareIds = (products: Product[]) => {
  return getCanonicalCompare(products).map(
    (product) => product.id || product.variantId
  );
};

export const getCanonicalCompare = (products: Product[]) => {
  const categoryName = products[0].metadata.categoryName;

  const isSpecialComparison =
    nonCanonicalVsPagesForSitemap[categoryName].length > 0 &&
    nonCanonicalVsPagesForSitemap[categoryName].find(
      (productSlugs) =>
        (productSlugs[0] === products[0].slug &&
          productSlugs[1] === products[1].slug) ||
        (productSlugs[0] === products[1].slug &&
          productSlugs[1] === products[0].slug)
    );

  if (isSpecialComparison) {
    return [products[0], products[1]];
  }

  const canonicalVariantSlugs = products.map((selectedProduct) => {
    if (selectedProduct.isCanonical) {
      return selectedProduct;
    } else {
      return selectedProduct.variants.find((variant) => variant.isCanonical);
    }
  });

  if (categoryName === "tvs") {
    const allAvailableSizes = products.map((product) => {
      const sizes = [];
      sizes.push(parseFloat(product.attributes["screenSize"].value));
      product.variants?.map((variant) => {
        sizes.push(parseFloat(variant.attributes["screenSize"]));
      });
      return sizes;
    });
    const canonicalVariantSizes = products.map((selectedProduct) => {
      if (selectedProduct.isCanonical) {
        return parseFloat(selectedProduct.attributes["screenSize"].value);
      } else {
        return parseFloat(
          selectedProduct.variants.find((variant) => variant.isCanonical)
            ?.attributes["screenSize"]
        );
      }
    });
    if (canonicalVariantSizes[0] !== canonicalVariantSizes[1]) {
      const smallerCanonicalSize = Math.min(...canonicalVariantSizes);
      const smallerCanonicalProductIndex =
        canonicalVariantSizes.indexOf(smallerCanonicalSize);

      const otherCanonicalSize = allAvailableSizes[
        1 - smallerCanonicalProductIndex
      ].sort(
        (a, b) =>
          Math.abs(a - smallerCanonicalSize) -
          Math.abs(b - smallerCanonicalSize)
      )[0];

      let smallerCanonicalProduct;
      let otherCanonicalProduct;

      const smallerProduct = products[smallerCanonicalProductIndex];
      const otherProduct = products[1 - smallerCanonicalProductIndex];

      if (
        parseFloat(smallerProduct.attributes["screenSize"].value) ===
        smallerCanonicalSize
      ) {
        smallerCanonicalProduct = smallerProduct;
      } else {
        smallerCanonicalProduct = smallerProduct.variants.find(
          (variant) =>
            parseFloat(variant.attributes["screenSize"]) ===
            smallerCanonicalSize
        );
      }

      if (
        parseFloat(otherProduct.attributes["screenSize"].value) ===
        otherCanonicalSize
      ) {
        otherCanonicalProduct = otherProduct;
      } else {
        otherCanonicalProduct = otherProduct.variants.find(
          (variant) =>
            parseFloat(variant.attributes["screenSize"]) === otherCanonicalSize
        );
      }

      return [smallerCanonicalProduct, otherCanonicalProduct];
    } else {
      return canonicalVariantSlugs;
    }
  }

  return canonicalVariantSlugs;
};

/**
 * This function finds the canonical variant slug of a given product
 */

export const getCanonicalSlug = (product: Product) => {
  if (product.isCanonical) {
    return product.slug;
  } else {
    return product.variants.find((variant) => variant.isCanonical)?.slug;
  }
};

/**
 * This function finds the canonical variant id of a given product
 */

export const getCanonicalId = (product: Product) => {
  if (product.isCanonical) {
    return product.id;
  } else {
    return product.variants.find((variant) => variant.isCanonical)?.variantId;
  }
};

/**
 * Truncate to a given number of digits instead of rounding
 */
export const toFixedWithoutRounding = (
  number: number,
  digits: number
): string => {
  const multiplier = Math.pow(10, digits);
  const truncatedNumber = Math.trunc(number * multiplier);
  const result = truncatedNumber / multiplier;
  return result.toFixed(digits); // Convert to string to ensure trailing zeros are maintained
};

/**
 * Parse OpenAI JSON wrapped in markdown
 */
export const parseOpenAIJSON = (markdown: string) => {
  const jsonMatch = markdown.match(/```json([\s\S]*?)```/);

  if (!jsonMatch || jsonMatch.length < 2) {
    console.error("Invalid or missing JSON in the markdown.");
    return null;
  }
  const jsonString = jsonMatch[1].trim();

  try {
    const jsonObject = JSON.parse(jsonString);
    return jsonObject;
  } catch (error) {
    console.error("Error parsing JSON: " + error.message);
    return null;
  }
};

export const joinWithAnd = (words: string[]) => {
  return words.length > 1
    ? words.slice(0, -1).join(", ") + ", and " + words[words.length - 1]
    : words[0];
};
//Gets the possible attribute values
//TODO: Rename
//TODO: Remove unneeded products
export const getAnnotations2 = (
  products: Product[],
  attributes: AttributeConfigurationMap
) => {
  const possibleValuesPerAttribute = {};

  const badValues = ["", "N/A", "Unknown"];

  products
    .filter(
      (product) =>
        product.attributes["priorityLevel"]?.value !== "" &&
        product?.bestPurchaseOption?.inStock
    )
    .forEach((product) => {
      Object.entries(product.attributes).map(
        ([key, attribute]: [string, Attribute]) => {
          if (!possibleValuesPerAttribute[key]) {
            possibleValuesPerAttribute[key] = new Set();
          }
          if (!badValues.includes(attribute.value))
            possibleValuesPerAttribute[key].add(attribute.value);
        }
      );
    });

  Object.keys(attributes).forEach((attribute) => {
    const values = Array.from(possibleValuesPerAttribute[attribute] || []);
    if (values.length > 0) {
      attributes[attribute] = {
        ...attributes[attribute],
        possibleValues: values.sort(),
      };
    }
  });

  return attributes;
};
