import { useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/outline";

interface ImageWithFallbackProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
  dataTestId?: string;
  tabIndex?: number;
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  return (
    <>
      {!imageLoadFailed && (
        <img
          src={props.src}
          alt={props.alt}
          width={props.width}
          height={props.height}
          className={props.className ?? ""}
          onError={() => setImageLoadFailed(true)}
          data-testid={props.dataTestId}
          tabIndex={props.tabIndex}
        />
      )}
      {imageLoadFailed && (
        <PhotoIcon
          data-testid={props.dataTestId}
          tabIndex={props.tabIndex}
          className="h-4 mx-auto my-auto"
        />
      )}
    </>
  );
};

export default ImageWithFallback;
